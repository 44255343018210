<template>
  <section class="member-level-expired section-block">
    <SectionTitle title="到期通知設定" @edit="showEditModal = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="到期通知發送天數">
        <div class="text-[#5E5E5E]">
          {{ displayData.expireNotificationBefore }}天
        </div>
      </el-form-item>
    </el-form>

    <MemberLevelExpiredNotifySettingEditModal
      v-if="showEditModal"
      :configData="configData"
      :onSubmit="onSubmit"
      @close="showEditModal = false"
    />
  </section>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import MemberLevelExpiredNotifySettingEditModal from './MemberLevelExpiredNotifySettingEditModal.vue'

export default defineComponent({
  name: 'MemberLevelExpiredNotifySetting',
  components: { MemberLevelExpiredNotifySettingEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
    updateMemberConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const showEditModal = ref(false)
    const displayData = computed(() => {
      return {
        expireNotificationBefore: props.configData.expireNotificationBefore,
      }
    })

    const onSubmit = async (expireNotificationBefore) => {
      await props.updateMemberConfig({
        newExpireNotificationBefore: expireNotificationBefore,
      })
      showEditModal.value = false
    }

    return { displayData, showEditModal, onSubmit }
  },
})
</script>

<style lang="postcss" scoped></style>
