<template>
  <section class="member-level-expired section-block">
    <SectionTitle title="會員級別機制設定" hideBtn />
    <el-form label-position="left">
      <el-form-item label="新會員加入時，自動成為等級階層 1 的會員">
        <el-switch
          v-model="displayData.autoLevel"
          inactive-text="關閉"
          active-text="開啟"
          @change="onChange"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'MemberLevelRulesSetting',
  props: {
    configData: { type: Object, default: () => ({}) },
    updateMemberConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const displayData = computed(() => {
      return {
        autoLevel: props.configData.autoLevel,
      }
    })

    const onChange = async () => {
      props.updateMemberConfig({ newAutoLevel: displayData.value.autoLevel })
    }

    return { displayData, onChange }
  },
})
</script>

<style lang="postcss" scoped></style>
