<template>
  <div class="member-level-settings">
    <PageTitle
      title="會員等級參數設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <MemberLevelExpiredNotifySetting
      :configData="formData"
      :updateMemberConfig="updateMemberConfig"
    />
    <MemberLevelRulesSetting
      :configData="formData"
      :updateMemberConfig="updateMemberConfig"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import PageTitle from '@/components/Title/PageTitle'
import MemberLevelExpiredNotifySetting from './components/MemberLevelExpiredNotifySetting.vue'
import MemberLevelRulesSetting from './components/MemberLevelRulesSetting.vue'
import { UpdateMemberConfig, GetMemberConfig } from '@/api/memberConfig'

export default defineComponent({
  name: 'MemberLevelSettings',
  components: {
    PageTitle,
    MemberLevelExpiredNotifySetting,
    MemberLevelRulesSetting,
  },
  setup (props) {
    const formData = reactive({
      autoLevel: false,
      expireNotificationBefore: 14,
    })
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()

    const updateMemberConfig = async ({
      newAutoLevel,
      newExpireNotificationBefore,
    }) => {
      const autoLevel =
        newAutoLevel === undefined ? formData.autoLevel : newAutoLevel
      const [, err] = await UpdateMemberConfig({
        shopId: shopId.value,
        defaultLevel: Number(autoLevel),
        expireNotificationBefore:
          newExpireNotificationBefore || formData.expireNotificationBefore,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      refresh()
    }

    const refresh = async () => {
      await simpleFetch(GetMemberConfig, { shopId: shopId.value }, (res) => {
        formData.autoLevel = Boolean(res.defaultLevel)
        formData.expireNotificationBefore = res.expireNotificationBefore
      })
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      formData,
      updateMemberConfig,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
